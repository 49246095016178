<!-- footer -->
    <!-- <div class="footer-top section-padding">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-credit-card-1"></i></span>
                        <span class="text-light-black">100% Payment<br>Secured</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-wallet-1"></i></span>
                        <span class="text-light-black">Support lots<br> of Payments</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6 mb-sm-20">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-help"></i></span>
                        <span class="text-light-black">24 hours / 7 days<br>Support</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-truck"></i></span>
                        <span class="text-light-black">Free Delivery<br>with $50</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-guarantee"></i></span>
                        <span class="text-light-black">Best Price<br>Guaranteed</span>
                    </div>
                </div>
                <div class="col-md-2 col-sm-4 col-6">
                    <div class="icon-box"> <span class="text-light-green"><i class="flaticon-app-file-symbol"></i></span>
                        <span class="text-light-black">Mobile Apps<br>Ready</span>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <footer class="section-padding bg-light-theme pt-0 u-line">
        <!-- <div class="u-line instagram-slider swiper-container">
            <ul class="hm-list hm-instagram swiper-wrapper">
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-3.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-1.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-2.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-4.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-5.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-6.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-7.jpg" alt="instagram"></a>
                </li>
                <li class="swiper-slide">
                    <a href="#"><img src="assets/img/restaurants/250x200/insta-8.jpg" alt="instagram"></a>
                </li>
            </ul>
        </div> -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl col-lg-4 col-md-4 col-sm-6"></div>
                <div class="col-xl col-lg-4 col-md-4 col-sm-6">
                    <div class="footer-contact">
                        <h6 class="text-custom-black">Need Help</h6>
                        <ul>
                            <li class="fw-600"><span class="text-light-white">Call Us </span> <a href="tel:" class="text-custom-black">+(1) 337 991 6542</a>
                            </li>
                            <li class="fw-600"><span class="text-light-white">Email Us </span> <a href="mailto:" class="text-custom-black">info@spicymanus.com</a>
                            </li>
                            <!-- <li class="fw-600"><span class="text-light-white">Join our twitter </span> <a href="#" class="text-custom-white">@quickmunch</a>
                            </li> -->
                            <li class="fw-600"><span class="text-light-white">Join our instagram </span> <a href="https://www.instagram.com/spicymanintl?igsh=MzJnbHduYTh6YTZw" target="_blank" class="text-custom-black">@spicymanintl</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl col-lg-4 col-md-4 col-sm-6">
                    <div class="footer-links">
                        <h6 class="text-light-black">Get to Know Us</h6>
                        <ul>
                            <li><a href="#" class="text-light-white fw-600">About Us</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Blog</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Socialize</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">SpicyManUS</a>
                            </li>
                            <li><a href="#" class="text-light-white fw-600">Perks</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="footer-contact">
                        <h6 class="text-custom-black">Locations</h6>
                        <ul>
                            <li class="fw-600"><span class="text-custom-black"> </span> <a href="https://g.co/kgs/6zXBVfw" class="text-custom-black" target="_blank">T. Moise Farms</a>
                            </li>
                            <li class="fw-600"><span class="text-custom-black"> </span> <a href="https://g.co/kgs/aFyuV3p" class="text-custom-black" target="_blank">Chicken On The Bayou & Boudin Shop</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl col-lg-4 col-md-4 col-sm-6"></div>
                <div class="col-md-12">
                    <div class="ft-social-media">
                        <h6 class="text-center text-light-white">Follow us</h6>
                        <ul>
                            <li> <a href="https://www.facebook.com/Spicymanllc?mibextid=LQQJ4d" target="_blank"><i class="fab fa-facebook-f"></i></a>
                            </li>
                            <!-- <li> <a href="#"><i class="fab fa-twitter"></i></a>
                            </li> -->
                            <li> <a href="https://www.instagram.com/spicymanintl?igsh=MzJnbHduYTh6YTZw" target="_blank"><i class="fab fa-instagram"></i></a>
                            </li>
                            <!-- <li> <a href="#"><i class="fab fa-pinterest-p"></i></a>
                            </li>
                            <li> <a href="#"><i class="fab fa-youtube"></i></a>
                            </li> -->
                        </ul>
                    </div>
                </div>
               
            </div>
        </div>
    </footer>
    <div class="copyright">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3">
                    <!-- <div class="payment-logo mb-md-20"> <span class="text-light-white fs-14 mr-3">We are accept</span>
                        <div class="payemt-icon">
                            <img src="assets/img/card-front.jpg" alt="#">
                            <img src="assets/img/visa.jpg" alt="#">
                            <img src="assets/img/amex-card-front.png" alt="#">
                            <img src="assets/img/mastercard.png" alt="#">
                        </div>
                    </div> -->
                </div>
             
                <div class="col-lg-4">
                    <div class="copyright-text align-self-center"> <span class="text-light-black">© <a href="https://spicymanus.com/" class="text-light-white">SpicyManUS</a> - 2024 | All Right Reserved</span>
                    </div>
                </div>
                <div class="col-lg-4 text-center medewithlove align-self-center">
                    <!-- <a href="https://metropolitanhost.com/" class="text-light-black">Made with Real <i class="fas fa-heart"></i> Metropolitanthemes</a> -->
                </div>
            </div>
        </div>
    </div>
    <!-- footer -->