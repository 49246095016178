
                    <div class="side-bar section-padding pb-0 mb-md-40">
                        <div class="main-box padding-20 side-blog mb-xl-20">
                            <h4 class="text-light-black">Popular Post</h4>
                            <article class="side-post pb-xl-20 mb-xl-20 u-line">
                                <div class="thumb-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog/100x100/popularpost-1.jpg" alt="thumb-img">
                                    </a>
                                </div>
                                <div class="content-wrap">
                                    <div class="entry-meta-content">
                                        <h5 class="entry-title">
                                            <a routerLink="/blog-details" class="text-light-black">Chicken Dishes You Should Eat Based on Your Horoscope</a>
                                        </h5>
                                        <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="side-post pb-xl-20 mb-xl-20 u-line">
                                <div class="thumb-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog/100x100/popularpost-2.jpg" alt="thumb-img">
                                    </a>
                                </div>
                                <div class="content-wrap">
                                    <div class="entry-meta-content">
                                        <h5 class="entry-title">
                                            <a routerLink="/blog-details" class="text-light-black">Chicken Dishes You Should Eat Based on Your Horoscope</a>
                                        </h5>
                                        <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="side-post pb-xl-20 mb-xl-20 u-line">
                                <div class="thumb-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog/100x100/popularpost-3.jpg" alt="thumb-img">
                                    </a>
                                </div>
                                <div class="content-wrap">
                                    <div class="entry-meta-content">
                                        <h5 class="entry-title">
                                            <a routerLink="/blog-details" class="text-light-black">Chicken Dishes You Should Eat Based on Your Horoscope</a>
                                        </h5>
                                        <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article class="side-post">
                                <div class="thumb-img">
                                    <a routerLink="/blog-details">
                                        <img src="assets/img/blog/100x100/popularpost-4.jpg" alt="thumb-img">
                                    </a>
                                </div>
                                <div class="content-wrap">
                                    <div class="entry-meta-content">
                                        <h5 class="entry-title">
                                            <a routerLink="/blog-details" class="text-light-black">Chicken Dishes You Should Eat Based on Your Horoscope</a>
                                        </h5>
                                        <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="main-box padding-20 blog-tags mb-xl-20">
                            <h4 class="text-light-black">Tags</h4>
                            <a href="#">#pizza</a>
                            <a href="#">#hot dog</a>
                            <a href="#">#fries</a>
                            <a href="#">#fried chicken</a>
                            <a href="#">#smoky chicken</a>
                            <a href="#">#soup</a>
                            <a href="#">#perry perry</a>
                            <a href="#">#cryspy crunch</a>
                            <a href="#">#green slad</a>
                            <a href="#">#chocolawa</a>
                            <a href="#">#butter bite</a>
                            <a href="#">#chicken role</a>
                            <a href="#">#salmon fish</a>

                        </div>
                        <div class="main-box padding-20 trending-blog-cat mb-xl-20">
                            <h4 class="text-light-black">Categories</h4>
                            <ul>
                                <li class="pb-xl-20 u-line mb-xl-20"> <a href="#" class="text-light-black fw-600">Pizza <span class="text-light-white fw-400">(110)</span></a>
                                </li>
                                <li class="pb-xl-20 u-line mb-xl-20"> <a href="#" class="text-light-black fw-600">Burger <span class="text-light-white fw-400">(98)</span></a>
                                </li>
                                <li class="pb-xl-20 u-line mb-xl-20"> <a href="#" class="text-light-black fw-600">Quick Noodles <span class="text-light-white fw-400">(52)</span></a>
                                </li>
                                <li class="pb-xl-20 u-line mb-xl-20"> <a href="#" class="text-light-black fw-600">Chilli Chicken <span class="text-light-white fw-400">(26)</span></a>
                                </li>
                                <li class="pb-xl-20 u-line mb-xl-20"> <a href="#" class="text-light-black fw-600">Spring Rolls <span class="text-light-white fw-400">(55)</span></a>
                                </li>
                                <li class="pb-xl-20 u-line mb-xl-20"> <a href="#" class="text-light-black fw-600">Chilli Potato <span class="text-light-white fw-400">(48)</span></a>
                                </li>
                                <li class="pb-xl-20 u-line mb-xl-20"> <a href="#" class="text-light-black fw-600">Chicken Soup <span class="text-light-white fw-400">(50)</span></a>
                                </li>

                            </ul>
                        </div>
                        <div class="main-box padding-20">
                            <div class="product-box mb-xl-20">
                                <div class="product-img">
                                    <a routerLink="/restaurant">
                                        <img src="assets/img/blog/406x239/shop-1.jpg" class="img-fluid full-width" alt="product-img">
                                    </a>
                                    <div class="overlay">
                                        <div class="product-tags padding-10"> <span class="circle-tag">
                        <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                      </span>
                                            <div class="custom-tag"> <span class="text-custom-white rectangle-tag bg-gradient-red">
                          20%
                        </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-caption">
                                    <div class="title-box">
                                        <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Great Burger</a></h6>
                                        <div class="tags"> <span class="text-custom-white rectangle-tag bg-red">
                        2.1
                      </span>
                                        </div>
                                    </div>
                                    <p class="text-light-white">American, Fast Food</p>
                                    <div class="product-details">
                                        <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                            <span class="text-light-white price">$10 min</span>
                                        </div>
                                        <div class="rating"> <span>
                        <i class="fas fa-star text-yellow"></i>
                        <i class="fas fa-star text-yellow"></i>
                        <i class="fas fa-star text-yellow"></i>
                        <i class="fas fa-star text-yellow"></i>
                        <i class="fas fa-star text-yellow"></i>
                      </span>
                                            <span class="text-light-white text-right">4225 ratings</span>
                                        </div>
                                    </div>
                                    <div class="product-footer"> <span class="text-custom-white square-tag">
                      <img src="assets/img/svg/008-protein.svg" alt="tag">
                    </span>
                                    </div>
                                </div>
                            </div>
                            <div class="product-box">
                                <div class="product-img">
                                    <a routerLink="/restaurant">
                                        <img src="assets/img/blog/406x239/shop-2.jpg" class="img-fluid full-width" alt="product-img">
                                    </a>
                                    <div class="overlay">
                                        <div class="product-tags padding-10"> <span class="circle-tag">
                        <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                      </span>
                                            <span class="type-tag bg-gradient-green text-custom-white">
                        Trending
                      </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-caption">
                                    <div class="title-box">
                                        <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black ">Flavor Town</a></h6>
                                        <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                        4.5
                      </span>
                                        </div>
                                    </div>
                                    <p class="text-light-white">Breakfast, Lunch & Dinner</p>
                                    <div class="product-details">
                                        <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                            <span class="text-light-white price">$10 min</span>
                                        </div>
                                        <div class="rating"> <span>
                        <i class="fas fa-star text-yellow"></i>
                        <i class="fas fa-star text-yellow"></i>
                        <i class="fas fa-star text-yellow"></i>
                        <i class="fas fa-star text-yellow"></i>
                        <i class="fas fa-star text-yellow"></i>
                      </span>
                                            <span class="text-light-white text-right">4225 ratings</span>
                                        </div>
                                    </div>
                                    <div class="product-footer"> <span class="text-custom-white square-tag">
                      <img src="assets/img/svg/005-chef.svg" alt="tag">
                    </span>
                                        <span class="text-custom-white square-tag">
                      <img src="assets/img/svg/004-leaf.svg" alt="tag">
                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               