import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogstyle2',
  templateUrl: './blogstyle2.component.html',
  styleUrls: ['./blogstyle2.component.css']
})
export class Blogstyle2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
