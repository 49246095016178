import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogrightsidebar',
  templateUrl: './blogrightsidebar.component.html',
  styleUrls: ['./blogrightsidebar.component.css']
})
export class BlogrightsidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
