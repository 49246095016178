
                    <div class="sidebar">
                        <div class="video-box mb-xl-20">
                            <div class="video_wrapper video_wrapper_full js-videoWrapper">
                                <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/DgwCCN5Qi3E" data-src="https://www.youtube.com/embed/DgwCCN5Qi3E?autoplay=1&amp;rel=0" allow="autoplay"></iframe>
                                <div class="videoPoster js-videoPoster">
                                    <div class="video-inner">
                                        <i class="far fa-play-circle"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="discount-box main-box padding-tb-10">
                                <div class="discount-price padding-10">
                                    <div class="left-side">
                                        <h6 class="text-light-black fw-600 no-margin">Watch Now and get 50% discount</h6>
                                        <p class="text-light-white no-margin">The hung-over foody (2022)</p>
                                    </div>
                                    <div class="right-side justify-content-end">
                                        <div class="dis-text">
                                            <span class="badge bg-light-green text-custom-white fw-400">Discount</span>
                                            <h4 class="text-light-black no-margin">50%</h4>
                                        </div>
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/logo-3.jpg" class="img-fluid" alt="logo">
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="cart-detail-box">
                            <div class="card">
                                <div class="card-header padding-15 fw-700">Your Order</div>
                                <div class="card-body no-padding" id="scrollstyle-4">
                                    <div class="cat-product-box">
                                        <div class="cat-product">
                                            <div class="cat-name">
                                                <a href="#">
                                                    <p class="text-light-green fw-700"><span class="text-dark-white">1</span> Chilli Chicken Pizza</p> <span class="text-light-white fw-700">small, chilli chicken</span>
                                                </a>
                                            </div>
                                            <div class="delete-btn">
                                                <a href="#" class="text-dark-white"> <i class="far fa-trash-alt"></i>
                                                </a>
                                            </div>
                                            <div class="price"> <a href="#" class="text-dark-white fw-500">
                          $2.25
                        </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cat-product-box">
                                        <div class="cat-product">
                                            <div class="cat-name">
                                                <a href="#">
                                                    <p class="text-light-green fw-700"><span class="text-dark-white">1</span> Chilli Chicken Pizza</p> <span class="text-light-white fw-700">small, chilli chicken</span>
                                                </a>
                                            </div>
                                            <div class="delete-btn">
                                                <a href="#" class="text-dark-white"> <i class="far fa-trash-alt"></i>
                                                </a>
                                            </div>
                                            <div class="price"> <a href="#" class="text-dark-white fw-500">
                          $2.25
                        </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cat-product-box">
                                        <div class="cat-product">
                                            <div class="cat-name">
                                                <a href="#">
                                                    <p class="text-light-green fw-700"><span class="text-dark-white">1</span> Chilli Chicken Pizza</p> <span class="text-light-white fw-700">small, chilli chicken</span>
                                                </a>
                                            </div>
                                            <div class="delete-btn">
                                                <a href="#" class="text-dark-white"> <i class="far fa-trash-alt"></i>
                                                </a>
                                            </div>
                                            <div class="price"> <a href="#" class="text-dark-white fw-500">
                          $2.25
                        </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cat-product-box">
                                        <div class="cat-product">
                                            <div class="cat-name">
                                                <a href="#">
                                                    <p class="text-light-green fw-700"><span class="text-dark-white">1</span> Chilli Chicken Pizza</p> <span class="text-light-white fw-700">small, chilli chicken</span>
                                                </a>
                                            </div>
                                            <div class="delete-btn">
                                                <a href="#" class="text-dark-white"> <i class="far fa-trash-alt"></i>
                                                </a>
                                            </div>
                                            <div class="price"> <a href="#" class="text-dark-white fw-500">
                          $2.25
                        </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-total">
                                        <div class="total-price border-0"> <span class="text-dark-white fw-700">Items subtotal:</span>
                                            <span class="text-dark-white fw-700">$9.00</span>
                                        </div>
                                        <div class="empty-bag padding-15 fw-700"> <a href="#">Empty bag</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer padding-15"> <a routerLink="/checkout" class="btn-first green-btn text-custom-white full-width fw-500">Proceed to Checkout</a>
                                </div>
                            </div>
                        </div>
                    </div>
              