<div id="banner-adv" class="banner-adv">
        <a routerLink="/orderdetails"> <span class="text">Get FREE DELIVERY on your first order.</span>
        </a> <span class="close-banner"></span>
    </div>

<app-navbar></app-navbar>

<!-- tracking map -->
    <section class="checkout-page section-padding bg-light-theme">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="tracking-sec">
                        <div class="tracking-details padding-20 p-relative">
                            <h5 class="text-light-black fw-600">Great Burger</h5>
                            <span class="text-light-white">Estimated Delivery time</span>
                            <h2 class="text-light-black fw-700 no-margin">9:00pm-9:10pm</h2>
                            <div id="add-listing-tab" class="step-app">
                                <ul class="step-steps">
                                    <li class="done">
                                        <a href="javascript:void(0)"> <span class="number"></span>
                                            <span class="step-name">Order sent<br>8:38pm</span>
                                        </a>
                                    </li>
                                    <li class="active">
                                        <a href="javascript:void(0)"> <span class="number"></span>
                                            <span class="step-name">In the works</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"> <span class="number"></span>
                                            <span class="step-name">Out of delivery</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)"> <span class="number"></span>
                                            <span class="step-name">Delivered</span>
                                        </a>
                                    </li>
                                </ul>
                                <button type="button" class="fullpageview text-light-black fw-600" data-modal="modal-12">Full Page View</button>
                            </div>
                        </div>
                        <div class="tracking-map">
                            <iframe id="pickupmap" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
                        </div>
                    </div>
                    <!-- recipt -->
                    <div class="recipt-sec padding-20">
                        <div class="recipt-name title u-line full-width mb-xl-20">
                            <div class="recipt-name-box">
                                <h5 class="text-light-black fw-600 mb-2">Great Burger</h5>
                                <p class="text-light-white ">Estimated Delivery time</p>
                            </div>
                            <div class="countdown-box">
                                <div class="time-box"> <span id="mb-hours"></span>
                                </div>
                                <div class="time-box"> <span id="mb-minutes"></span>
                                </div>
                                <div class="time-box"> <span id="mb-seconds"></span>
                                </div>
                            </div>
                        </div>
                        <div class="u-line mb-xl-20">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="recipt-name full-width padding-tb-10 pt-0">
                                        <h5 class="text-light-black fw-600">Delivery (ASAP) to:</h5>
                                        <span class="text-light-white ">Jhon Deo</span>
                                        <span class="text-light-white ">Home</span>
                                        <span class="text-light-white ">314 79th St</span>
                                        <span class="text-light-white ">Rite Aid, Brooklyn, NY, 11209</span>
                                        <p class="text-light-white ">(347) 123456789</p>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="recipt-name full-width padding-tb-10 pt-0">
                                        <h5 class="text-light-black fw-600">Delivery instructions</h5>
                                        <p class="text-light-white ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua consectetur adipiscing elit.</p>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="ad-banner padding-tb-10 h-100">
                                        <img src="assets/img/details/banner.jpg" class="img-fluid full-width" alt="banner-adv">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="u-line mb-xl-20">
                            <div class="row">
                                <div class="col-lg-12">
                                    <h5 class="text-light-black fw-600 title">Your Order <span><a routerLink="/orderdetails" class="fs-12">Print recipt</a></span></h5>
                                    <p class="title text-light-white">Nov 15, 2015 8:38pm <span class="text-light-black">Order #123456789012345</span>
                                    </p>
                                </div>
                                <div class="col-lg-12">
                                    <div class="checkout-product">
                                        <div class="img-name-value">
                                            <div class="product-img">
                                                <a routerLink="/orderdetails">
                                                    <img src="assets/img/details/dish-1.jpg" alt="#">
                                                </a>
                                            </div>
                                            <div class="product-value"> <span class="text-light-white">2</span>
                                            </div>
                                            <div class="product-name"> <span><a routerLink="/orderdetails" class="text-light-white">Steak Burger</a></span>
                                            </div>
                                        </div>
                                        <div class="price"> <span class="text-light-white">$20.99</span>
                                        </div>
                                    </div>
                                    <div class="checkout-product">
                                        <div class="img-name-value">
                                            <div class="product-img">
                                                <a routerLink="/orderdetails">
                                                    <img src="assets/img/details/dish-2.jpg" alt="#">
                                                </a>
                                            </div>
                                            <div class="product-value"> <span class="text-light-white">2</span>
                                            </div>
                                            <div class="product-name"> <span><a routerLink="/orderdetails" class="text-light-white">Cheese Burger</a></span>
                                            </div>
                                        </div>
                                        <div class="price"> <span class="text-light-white">$14.50</span>
                                        </div>
                                    </div>
                                    <div class="checkout-product">
                                        <div class="img-name-value">
                                            <div class="product-img">
                                                <a routerLink="/orderdetails">
                                                    <img src="assets/img/details/dish-3.jpg" alt="#">
                                                </a>
                                            </div>
                                            <div class="product-value"> <span class="text-light-white">2</span>
                                            </div>
                                            <div class="product-name"> <span><a routerLink="/orderdetails" class="text-light-white">Double Burger</a></span>
                                            </div>
                                        </div>
                                        <div class="price"> <span class="text-light-white">$15.50</span>
                                        </div>
                                    </div>
                                    <div class="checkout-product">
                                        <div class="img-name-value">
                                            <div class="product-img">
                                                <a routerLink="/orderdetails">
                                                    <img src="assets/img/details/dish-4.jpg" alt="#">
                                                </a>
                                            </div>
                                            <div class="product-value"> <span class="text-light-white">2</span>
                                            </div>
                                            <div class="product-name"> <span><a routerLink="/orderdetails" class="text-light-white">Big Cheese Burger</a></span>
                                            </div>
                                        </div>
                                        <div class="price"> <span class="text-light-white">$30.5</span>
                                        </div>
                                    </div>
                                    <div class="checkout-product">
                                        <div class="img-name-value">
                                            <div class="product-img">
                                                <a routerLink="/orderdetails">
                                                    <img src="assets/img/details/dish-5.jpg" alt="#">
                                                </a>
                                            </div>
                                            <div class="product-value"> <span class="text-light-white">2</span>
                                            </div>
                                            <div class="product-name"> <span><a routerLink="/orderdetails" class="text-light-white">Cheesy Fries</a></span>
                                            </div>
                                        </div>
                                        <div class="price"> <span class="text-light-white">$5.45</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="payment-method mb-md-40">
                                    <h5 class="text-light-black fw-600">Payment Method</h5>
                                    <div class="method-type"> <i class="far fa-credit-card text-dark-white"></i>
                                        <span class="text-light-white">Credit Card</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="price-table u-line">
                                    <div class="item"> <span class="text-light-white">Item subtotal:</span>
                                        <span class="text-light-white">$30.5</span>
                                    </div>
                                    <div class="item"> <span class="text-light-white">Delivery fee:</span>
                                        <span class="text-light-white">$30.5</span>
                                    </div>
                                    <div class="item"> <span class="text-light-white">Tax and fees:</span>
                                        <span class="text-light-white">$30.5</span>
                                    </div>
                                    <div class="item"> <span class="text-light-white">Driver tip:</span>
                                        <span class="text-light-white">$30.5</span>
                                    </div>
                                </div>
                                <div class="total-price padding-tb-10">
                                    <h5 class="title text-light-black fw-700">Total: <span>$33.36</span></h5>
                                </div>
                            </div>
                            <div class="col-12 d-flex"> <a routerLink="/orderdetails" class="btn-first white-btn fw-600 help-btn">Need Help?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- tracking map -->

    <!-- modal boxes -->
    <div class="modal fade" id="offer">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header no-padding">
                    <div class="offer-content">
                        <h2 class="text-custom-white fw-700">Get $12 off <small class=" fw-700">your order*</small></h2>
                        <p class="text-custom-white">As an added treat, enjoy <strong>free delivery</strong> from
                            <br>select restaurants automatically applied at checkout</p>
                    </div>
                    <button type="button" class="close" data-dismiss="modal">X</button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="text-light-white fs-14">E-mail</label>
                                    <input type="email" name="#" class="form-control form-control-submit" placeholder="E-mail ID">
                                </div>
                                <div class="form-group">
                                    <label class="text-light-white fs-14">ZIP Code</label>
                                    <input type="number" name="#" class="form-control form-control-submit" placeholder="10421">
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn-second btn-submit full-width">Save $12 on your first order</button>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn-second btn-default full-width text-light-green fw-700" data-dismiss="modal">Dismiss</button>
                                </div>
                                <div class="text-center"> <span class="text-light-black fs-12">*Valid on first order only, for one-time use, subject to quickmunch’s verification. Additional terms may apply. By signing up, you agree to receive marketing and promotional emails and communications form quickmunch</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="address-box">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title fw-700">Change Address</h4>
                </div>
                <div class="modal-body">
                    <div class="location-picker">
                        <input type="text" class="form-control" placeholder="Enter a new address">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="search-box">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <div class="search-box p-relative full-width">
                        <input type="text" class="form-control" placeholder="Pizza, Burger, Chinese">
                    </div>
                </div>
                <div class="modal-body"></div>
            </div>
        </div>
    </div>
    <div class="md-modal md-effect-12">
        <div class="md-content">
            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-lg-3">
                        <div class="modal-sidebar padding-20">
                            <div class="content-box padding-15 u-line">
                                <h5 class="text-light-black title fw-600 no-margin">Delivered <span><a routerLink="/orderdetails" class="fs-14">Help</a></span></h5>
                                <p class="text-light-white mb-xl-20">Five Guys 8:56pm arrival</p>
                                <div id="add-listing-tab1" class="step-app mb-xl-20">
                                    <ul class="step-steps">
                                        <li class="done order-note">
                                            <a href="javascript:void(0)"> <span class="number"></span>
                                            </a>
                                        </li>
                                        <li class="active make-order">
                                            <a href="javascript:void(0)"> <span class="number"></span>
                                            </a>
                                        </li>
                                        <li class="bike">
                                            <a href="javascript:void(0)"> <span class="number"></span>
                                            </a>
                                        </li>
                                        <li class="home-delivery">
                                            <a href="javascript:void(0)"> <span class="number"></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="rating-box"> <span class="text-light-black fs-12">Rate your delivery</span>
                                    <div class="ratings"> <span class="text-light-white">
                      <i class="fas fa-star"></i>
                    </span>
                                        <span class="text-light-white">
                      <i class="fas fa-star"></i>
                    </span>
                                        <span class="text-light-white">
                      <i class="fas fa-star"></i>
                    </span>
                                        <span class="text-light-white">
                      <i class="fas fa-star"></i>
                    </span>
                                        <span class="text-light-white">
                      <i class="fas fa-star"></i>
                    </span>
                                        <span class="arrow"><a routerLink="/orderdetails"><i class="fas fa-chevron-right"></i></a></span>
                                    </div>
                                </div>
                            </div>
                            <div class="content-box padding-15 u-line">
                                <h6 class="text-light-black fw-500 mb-2 title">Five Guys <span><a routerLink="/orderdetails" class="fs-12"><i class="fas fa-chevron-right"></i></a></span></h6>
                                <p class="text-light-black mb-1">2 Items</p>
                                <p class="text-light-white no-margin fs-12">1x Little Hamburger</p>
                                <p class="text-light-white no-margin fs-12">1x Hamburger</p>
                            </div>
                            <div class="content-box padding-15 u-line">
                                <h6 class="text-light-black fw-500 mb-2 title">Delivery Address</h6>
                                <p class="text-light-black no-margin">314 79th St, Brooklyn, NY 11209, USA</p>
                            </div>
                            <button class="md-close btn-second btn-submit full-width"><i class="fas fa-chevron-left"></i> Go Back</button>
                        </div>
                    </div>
                    <div class="col-lg-9 no-padding">
                        <iframe id="pickupmap2" src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="md-overlay"></div>

    <app-footer2></app-footer2>