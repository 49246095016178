<app-navbar></app-navbar>

<!-- banner 2 -->
    <section class="banner-1 banner-2 p-relative ">
        <img src="assets/img/banner/banner-4.jpg" class="img-fluid full-width" alt="Banner">
        <div class="transform-center">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 align-self-center">
                        <div class="right-side-content">
                            <h1 class="text-custom-white fw-600">Increase takeout sales by 50%</h1>
                            <h3 class="text-custom-white fw-400">with the largest delivery platform in the U.S. and Canada</h3>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="content-wrapper bg-white padding-20">
                            <div class="content-box padding-tb-10 text-center">
                                <h3 class="text-light-black fw-700">Get started with quickmunch today!</h3>
                                <form>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <input type="text" name="#" class="form-control form-control-submit" placeholder="restaurent Name">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" name="#" class="form-control form-control-submit" placeholder="restaurent Address">
                                            </div>
                                            <div class="form-group">
                                                <input type="email" name="#" class="form-control form-control-submit" placeholder="Email I'D">
                                            </div>
                                            <div class="form-group">
                                                <input type="text" name="#" class="form-control form-control-submit" placeholder="Mobile Number">
                                            </div>
                                            <div class="form-group">
                                                <button type="submit" class="btn-second btn-submit full-width">Get Started</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlay overlay-bg"></div>
    </section>
    <!-- banner 2 -->
    <!-- Browse by category -->
    <section class="browse-cat browse-cat-2 u-line section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title title">Browse by cuisine <span class="fs-14"><a routerLink="/restaurant">See all restaurent</a></span></h3>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                    <a routerLink="/homepage4" class="categories mb-md-20">
                        <div class="icon text-custom-white bg-light-green ">
                            <img src="assets/img/restaurants/160x160/bcuisine-1.jpg" class="img-fluid full-width" alt="categories">
                            <div class="overlay">
                                <div class="product-addbtn"> <span class="add-product"><i class="fas fa-plus"></i></span>
                                </div>
                            </div>
                        </div> <span class="text-light-black cat-name">Italian</span>
                    </a>
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                    <a routerLink="/restaurant" class="categories mb-md-20">
                        <div class="icon text-custom-white bg-light-green ">
                            <img src="assets/img/restaurants/160x160/bcuisine-2.jpg" class="img-fluid full-width" alt="categories">
                            <div class="overlay">
                                <div class="product-addbtn"> <span class="add-product"><i class="fas fa-plus"></i></span>
                                </div>
                            </div>
                        </div> <span class="text-light-black cat-name">Thai</span>
                    </a>
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                    <a routerLink="/restaurant" class="categories mb-md-20">
                        <div class="icon text-custom-white bg-light-green ">
                            <img src="assets/img/restaurants/160x160/bcuisine-3.jpg" class="img-fluid full-width" alt="categories">
                            <div class="overlay">
                                <div class="product-addbtn"> <span class="add-product"><i class="fas fa-plus"></i></span>
                                </div>
                            </div>
                        </div> <span class="text-light-black cat-name">Chinese</span>
                    </a>
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                    <a routerLink="/restaurant" class="categories mb-sm-20">
                        <div class="icon text-custom-white bg-light-green ">
                            <img src="assets/img/restaurants/160x160/bcuisine-4.jpg" class="img-fluid full-width" alt="categories">
                            <div class="overlay">
                                <div class="product-addbtn"> <span class="add-product"><i class="fas fa-plus"></i></span>
                                </div>
                            </div>
                        </div> <span class="text-light-black cat-name">Mexican</span>
                    </a>
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                    <a routerLink="/restaurant" class="categories">
                        <div class="icon text-custom-white bg-light-green ">
                            <img src="assets/img/restaurants/160x160/bcuisine-5.jpg" class="img-fluid full-width" alt="categories">
                            <div class="overlay">
                                <div class="product-addbtn"> <span class="add-product"><i class="fas fa-plus"></i></span>
                                </div>
                            </div>
                        </div> <span class="text-light-black cat-name">Indian</span>
                    </a>
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                    <a routerLink="/restaurant" class="categories">
                        <div class="icon text-custom-white bg-light-green ">
                            <img src="assets/img/restaurants/160x160/bcuisine-8.jpg" class="img-fluid full-width" alt="categories">
                            <div class="overlay">
                                <div class="product-addbtn"> <span class="add-product"><i class="fas fa-plus"></i></span>
                                </div>
                            </div>
                        </div> <span class="text-light-black cat-name">Lebanese</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
    <!-- Browse by category -->
    <!-- Explore collection -->
    <section class="ex-collection section-padding bg-light-theme">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="ex-collection-box mb-xl-20">
                        <img src="assets/img/restaurants/540x300/collection-3.jpg" class="img-fluid full-width" alt="image">
                        <div class="category-type overlay padding-15"> <a routerLink="/restaurant" class="category-btn">Top rated</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="ex-collection-box mb-xl-20">
                        <img src="assets/img/restaurants/540x300/collection-4.jpg" class="img-fluid full-width" alt="image">
                        <div class="category-type overlay padding-15"> <a routerLink="/restaurant" class="category-btn">Top rated</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-40.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <div class="custom-tag"> <span class="text-custom-white rectangle-tag bg-gradient-red">
                      10%
                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Great Burger</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-yellow">
                    3.1
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">American, Fast Food</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/004-leaf.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/006-chili.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/005-chef.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/008-protein.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/009-lemon.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-39.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Flavor Town</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-red">
                    2.1
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Breakfast, Lunch & Dinner</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/007-chili-1.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/004-leaf.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/009-lemon.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-38.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <span class="type-tag bg-gradient-green text-custom-white">
                    Trending
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Big Bites</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                    4.5
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Pizzas, Fast Food</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/005-chef.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/004-leaf.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xs-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-37.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <span class="type-tag bg-gradient-green text-custom-white">
                    Trending
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Smile N’ Delight</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                    4.5
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Desserts, Beverages</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/005-chef.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/008-protein.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/009-lemon.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xs-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-36.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <div class="custom-tag"> <span class="text-custom-white rectangle-tag bg-gradient-red">
                      20%
                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Lil Johnny’s</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-red">
                    2.1
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Continental & Mexican</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/008-protein.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-35.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <span class="text-custom-white type-tag bg-gradient-orange">
                    NEW
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Choice Foods</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                    4.5
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Indian, Chinese, Tandoor</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/005-chef.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/008-protein.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/009-lemon.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Explore collection -->
    <!-- blog -->
    <section class="section-padding our-blog">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="blog-box">
                        <!-- article 1 -->
                        <article class="post">
                            <div class="blog-img p-relative">
                                <a routerLink="/homepage4">
                                    <img src="assets/img/about/blog/740x442/blog-2.jpg" alt="blog-img">
                                </a>
                                <div class="overlay padding-20">
                                    <div class="blog-meta">
                                        <h4 class="text-custom-white mb-2 fw-600">New locations where you can now get your quickmunch® delivered</h4>
                                        <a routerLink="/homepage4" class="text-custom-white fw-700 fs-18">Read More</a>
                                    </div>
                                </div>
                                <div class="overlay overlay-bg"></div>
                            </div>
                        </article>
                        <!-- article 1 -->
                        <div class="side-blog">
                            <div class="blog-list padding-tb-10">
                                <!-- article 1 -->
                                <article class="side-post padding-20 u-line">
                                    <div class="content-wrap">
                                        <div class="entry-meta-content">
                                            <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                            </div>
                                        </div>
                                        <h6 class="entry-title no-margin">
                                            <a routerLink="/blog-details" class="text-light-black">How We Made It: Grimaldi's traditional New York-style pizza</a>
                                        </h6>
                                    </div>
                                    <div class="thumb-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/100x100/popularpost-4.jpg" alt="thumb-img">
                                        </a>
                                    </div>
                                </article>
                                <!-- article 1 -->
                                <!-- article 1 -->
                                <article class="side-post padding-20 u-line">
                                    <div class="content-wrap">
                                        <div class="entry-meta-content">
                                            <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                            </div>
                                        </div>
                                        <h6 class="entry-title no-margin">
                                            <a routerLink="/blog-details" class="text-light-black">How We Made It: Grimaldi's traditional New York-style pizza</a>
                                        </h6>
                                    </div>
                                    <div class="thumb-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/100x100/popularpost-3.jpg" alt="thumb-img">
                                        </a>
                                    </div>
                                </article>
                                <!-- article 1 -->
                                <!-- article 1 -->
                                <article class="side-post padding-20">
                                    <div class="content-wrap">
                                        <div class="entry-meta-content">
                                            <div class="entry-tye"> <span class="text-light-green fw-600">20 Nov 2022</span>
                                            </div>
                                        </div>
                                        <h6 class="entry-title no-margin">
                                            <a routerLink="/blog-details" class="text-light-black">How We Made It: Grimaldi's traditional New York-style pizza</a>
                                        </h6>
                                    </div>
                                    <div class="thumb-img">
                                        <a routerLink="/blog-details">
                                            <img src="assets/img/blog/100x100/popularpost-2.jpg" alt="thumb-img">
                                        </a>
                                    </div>
                                </article>
                                <!-- article 1 -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- blog -->
    <!--advertise  -->
    <div class="restaurent-ad section-padding">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="ad-img p-relative">
                        <img src="assets/img/about/blog/1110x350/hbanner-2.jpg" class="img-fluid full-width" alt="restaurant.html">
                        <div class="overlay">
                            <div class="content-box transform-center">
                                <p class="text-custom-white">Quickmunch</p>
                                <h3 class="text-custom-white mb-1">More than 3000 restaurents</h3>
                                <h5 class="text-custom-white">Book a table easly at the best price</h5>
                                <a routerLink="/restaurant" class="btn-first white-btn">View All</a>
                            </div>
                        </div>
                        <div class="overlay overlay-bg"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--advertise  -->
    <!-- Explore collection -->
    <section class="ex-collection section-padding bg-light-theme">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="ex-collection-box mb-xl-20">
                        <img src="assets/img/restaurants/540x300/collection-5.jpg" class="img-fluid full-width" alt="image">
                        <div class="category-type overlay padding-15"> <a routerLink="/restaurant" class="category-btn">Top rated</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="ex-collection-box mb-xl-20">
                        <img src="assets/img/restaurants/540x300/collection-6.jpg" class="img-fluid full-width" alt="image">
                        <div class="category-type overlay padding-15"> <a routerLink="/restaurant" class="category-btn">Top rated</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-34.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <div class="custom-tag"> <span class="text-custom-white rectangle-tag bg-gradient-red">
                      10%
                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black ">Great Burger</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-yellow">
                    3.1
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">American, Fast Food/p>
                                <div class="product-details">
                                    <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                        <span class="text-light-white price">$10 min</span>
                                    </div>
                                    <div class="rating"> <span>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                    </span>
                                        <span class="text-light-white text-right">4225 ratings</span>
                                    </div>
                                </div>
                                <div class="product-footer"> <span class="text-custom-white square-tag">
                    <img src="assets/img/svg/004-leaf.svg" alt="tag">
                  </span>
                                    <span class="text-custom-white square-tag">
                    <img src="assets/img/svg/006-chili.svg" alt="tag">
                  </span>
                                    <span class="text-custom-white square-tag">
                    <img src="assets/img/svg/005-chef.svg" alt="tag">
                  </span>
                                    <span class="text-custom-white square-tag">
                    <img src="assets/img/svg/008-protein.svg" alt="tag">
                  </span>
                                    <span class="text-custom-white square-tag">
                    <img src="assets/img/svg/009-lemon.svg" alt="tag">
                  </span>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-33.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black ">Flavor Town</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-red">
                    2.1
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Breakfast, Lunch & Dinner</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/007-chili-1.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/004-leaf.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/009-lemon.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-32.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <span class="type-tag bg-gradient-green text-custom-white">
                    Trending
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Big Bites</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                    4.5
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Pizzas, Fast Food</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/005-chef.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/004-leaf.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xs-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-31.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <span class="type-tag bg-gradient-green text-custom-white">
                    Trending
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Smile N’ Delight</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                    4.5
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Desserts, Beverages</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/005-chef.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/008-protein.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/009-lemon.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box mb-xs-20">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-30.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <div class="custom-tag"> <span class="text-custom-white rectangle-tag bg-gradient-red">
                      20%
                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Lil Johnny’s</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-red">
                    2.1
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Continental & Mexican</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/008-protein.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                    <div class="product-box">
                        <div class="product-img">
                            <a routerLink="/restaurant">
                                <img src="assets/img/restaurants/255x150/shop-29.jpg" class="img-fluid full-width" alt="product-img">
                            </a>
                            <div class="overlay">
                                <div class="product-tags padding-10"> <span class="circle-tag">
                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                  </span>
                                    <span class="text-custom-white type-tag bg-gradient-orange">
                    NEW
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-caption">
                            <div class="title-box">
                                <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black "> Choice Foods</a></h6>
                                <div class="tags"> <span class="text-custom-white rectangle-tag bg-green">
                    4.5
                  </span>
                                </div>
                            </div>
                            <p class="text-light-white">Indian, Chinese, Tandoor</p>
                            <div class="product-details">
                                <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                    <span class="text-light-white price">$10 min</span>
                                </div>
                                <div class="rating"> <span>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                    <i class="fas fa-star text-yellow"></i>
                  </span>
                                    <span class="text-light-white text-right">4225 ratings</span>
                                </div>
                            </div>
                            <div class="product-footer"> <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/005-chef.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/008-protein.svg" alt="tag">
                </span>
                                <span class="text-custom-white square-tag">
                  <img src="assets/img/svg/009-lemon.svg" alt="tag">
                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Explore collection -->
    <!-- kosher delivery -->
    <section class="section-padding">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-4 col-md-6 ">
                    <div class="product-box-3 mb-lg-20">
                        <div class="product-img">
                            <a routerLink="/homepage4">
                                <img src="assets/img/restaurants/604x240/shop-1.jpg" class="img-fluid full-width" alt="#">
                            </a>
                        </div>
                        <div class="product-caption">
                            <div class="padding-tb-10">
                                <h5 class="mb-1"><a routerLink="/homepage4" class="text-light-black">Quickmunch® (Steak House)</a></h5>
                                <ul class="food-type">
                                    <li class="text-light-white">$</li>
                                    <li class="text-light-white">American</li>
                                    <li class="text-light-white">Fast Food</li>
                                    <li class="text-light-white">Burger</li>
                                </ul>
                                <ul class="food-description">
                                    <li class="text-light-black">20-30 Min</li>
                                    <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                    <li class="text-light-black">$2.99 Delivery Fee</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 ">
                    <div class="product-box-3 mb-lg-20">
                        <div class="product-img">
                            <a routerLink="/homepage4">
                                <img src="assets/img/restaurants/604x240/shop-2.jpg" class="img-fluid full-width" alt="#">
                            </a>
                        </div>
                        <div class="product-caption">
                            <div class="padding-tb-10">
                                <h5 class="mb-1"><a routerLink="/homepage4" class="text-light-black">Mcvolant® (Vegiterian)</a></h5>
                                <ul class="food-type">
                                    <li class="text-light-white">$</li>
                                    <li class="text-light-white">American</li>
                                    <li class="text-light-white">Fast Food</li>
                                    <li class="text-light-white">Burger</li>
                                </ul>
                                <ul class="food-description">
                                    <li class="text-light-black">20-30 Min</li>
                                    <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                    <li class="text-light-black">$2.99 Delivery Fee</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-6 ">
                    <div class="product-box-3">
                        <div class="product-img">
                            <a routerLink="/homepage4">
                                <img src="assets/img/restaurants/604x240/shop-3.jpg" class="img-fluid full-width" alt="#">
                            </a>
                        </div>
                        <div class="product-caption">
                            <div class="padding-tb-10">
                                <h5 class="mb-1"><a routerLink="/homepage4" class="text-light-black">ckickchbox® (Specialty Chicken)</a></h5>
                                <ul class="food-type">
                                    <li class="text-light-white">$</li>
                                    <li class="text-light-white">American</li>
                                    <li class="text-light-white">Fast Food</li>
                                    <li class="text-light-white">Burger</li>
                                </ul>
                                <ul class="food-description">
                                    <li class="text-light-black">20-30 Min</li>
                                    <li class="text-light-black">4.2 <i class="fas fa-star text-yellow"></i> (200+)</li>
                                    <li class="text-light-black">$2.99 Delivery Fee</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- kosher delivery -->

    <app-footer1></app-footer1>