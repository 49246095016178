<app-navbar></app-navbar>
<section class="section-padding contact-top bg-light-theme">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info-box mb-md-40">
                    <i class="fas fa-map-marker-alt"></i>
                    <h6 class="text-theme fw-600">PO BOX 1744 Scott Louisiana<br> LA 70583, USA</h6>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info-box mb-md-40">
                    <i class="fas fa-phone-alt"></i>
                    <h6 class="text-theme fw-600"><a href="#" class="text-theme">+(1) 337 991 6542</a><br> Mon-Sat
                        9:00am-5:00pm</h6>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info-box">
                    <i class="fas fa-envelope"></i>
                    <h6 class="text-theme fw-600"><a href="#" class="text-theme">info@spicymanus.com</a><br> 24 X 7 online
                        support</h6>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="contact-map">
    <iframe
        src="https://maps.google.com/maps?q=university%20of%20san%20francisco&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
</div> -->
<app-footer2></app-footer2>