
                    <div class="side-bar section-padding pb-0">
                        <div class="advertisement-slider swiper-container h-auto mb-xl-20">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="testimonial-wrapper">
                                        <div class="testimonial-box">
                                            <div class="testimonial-img p-relative">
                                                <a routerLink="/restaurant">
                                                    <img src="assets/img/blog/438x180/shop-2.jpg" class="img-fluid full-width" alt="testimonial-img">
                                                </a>
                                                <div class="overlay">
                                                    <div class="brand-logo">
                                                        <img src="assets/img/user/user-1.png" class="img-fluid" alt="logo">
                                                    </div>
                                                    <div class="add-fav text-light-white"><img src="assets/img/svg/013-heart-1.svg" alt="tag">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="testimonial-caption padding-15">
                                                <p class="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                                <h5 class="fw-600"><a routerLink="/restaurant" class="text-light-black">GSA King Tomato Farm</a></h5>
                                                <div class="testimonial-user-box">
                                                    <img src="assets/img/blog-details/40x40/user-2.png" class="rounded-circle" alt="#">
                                                    <div class="testimonial-user-name">
                                                        <p class="text-light-black fw-600">Sarra</p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                    </div>
                                                </div>
                                                <div class="ratings"> <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                </div>
                                                <p class="text-light-black">Delivery was fast and friendly...</p>
                                                <p class="text-light-white fw-100"><strong class="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>

                                                <a routerLink="/checkout" class="btn-second btn-submit">Order Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="testimonial-wrapper">
                                        <div class="testimonial-box">
                                            <div class="testimonial-img p-relative">
                                                <a routerLink="/restaurant">
                                                    <img src="assets/img/blog/438x180/shop-3.jpg" class="img-fluid full-width" alt="testimonial-img">
                                                </a>
                                                <div class="overlay">
                                                    <div class="brand-logo">
                                                        <img src="assets/img/user/user-1.png" class="img-fluid" alt="logo">
                                                    </div>
                                                    <div class="add-fav text-light-white"><img src="assets/img/svg/013-heart-1.svg" alt="tag">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="testimonial-caption padding-15">
                                                <p class="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                                <h5 class="fw-600"><a routerLink="/restaurant" class="text-light-black">GSA King Tomato Farm</a></h5>
                                                <div class="testimonial-user-box">
                                                    <img src="assets/img/blog-details/40x40/user-3.png" class="rounded-circle" alt="restaurant.html">
                                                    <div class="testimonial-user-name">
                                                        <p class="text-light-black fw-600">Sarra</p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                    </div>
                                                </div>
                                                <div class="ratings"> <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                </div>
                                                <p class="text-light-black">Delivery was fast and friendly...</p>
                                                <p class="text-light-white fw-100"><strong class="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                                <a routerLink="/checkout" class="btn-second btn-submit">Order Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="testimonial-wrapper">
                                        <div class="testimonial-box">
                                            <div class="testimonial-img p-relative">
                                                <a routerLink="/restaurant">
                                                    <img src="assets/img/blog/438x180/shop-1.jpg" class="img-fluid full-width" alt="testimonial-img">
                                                </a>
                                                <div class="overlay">
                                                    <div class="brand-logo">
                                                        <img src="assets/img/user/user-2.png" class="img-fluid" alt="logo">
                                                    </div>
                                                    <div class="add-fav text-light-white"><img src="assets/img/svg/013-heart-1.svg" alt="tag">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="testimonial-caption padding-15">
                                                <p class="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                                <h5 class="fw-600"><a routerLink="/restaurant" class="text-light-black">GSA King Tomato Farm</a></h5>
                                                <div class="testimonial-user-box">
                                                    <img src="assets/img/blog-details/40x40/user-1.png" class="rounded-circle" alt="restaurant.html">
                                                    <div class="testimonial-user-name">
                                                        <p class="text-light-black fw-600">Sarra</p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                    </div>
                                                </div>
                                                <div class="ratings"> <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                    <span class="text-yellow fs-16">
                            <i class="fas fa-star"></i>
                          </span>
                                                </div>
                                                <p class="text-light-black">Delivery was fast and friendly...</p>
                                                <p class="text-light-white fw-100"><strong class="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                                <a routerLink="/checkout" class="btn-second btn-submit">Order Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Add Arrows -->
                            <div class="swiper-button-next"></div>
                            <div class="swiper-button-prev"></div>
                        </div>
                        <div class="large-product-box mb-xl-20">
                            <img src="assets/img/blog/446x1025/ad-1.jpg" class="img-fluid full-width" alt="image">
                            <div class="category-type overlay padding-15">
                                <button class="category-btn">Most popular near you</button> <a routerLink="/restaurant" class="btn-first white-btn text-light-black fw-600 full-width">See all</a>
                            </div>
                        </div>
                        <div class="inner-wrapper main-box">
                            <div class="main-banner p-relative">
                                <img src="assets/img/blog/446x501/ff-1.jpg" class="img-fluid full-width main-img" alt="banner">
                                <div class="overlay-2 main-padding">
                                    <img src="assets/img/logo-2.jpg" class="img-fluid" alt="logo">
                                </div>
                                <img src="assets/img/banner/burger.png" class="footer-img" alt="footerimg">
                            </div>
                            <div class="section-2 main-page main-padding">
                                <div class="login-box">
                                    <h3 class="text-light-black fw-700">Quickmunch food delivery every time</h3>
                                    <div class="input-group row">
                                        <div class="input-group2 col-xl-8">
                                            <input type="search" class="form-control form-control-submit" placeholder="Enter street address or zip code" value="1246 57th St, Brooklyn, NY, 11219">
                                            <div class="input-group-prepend">
                                                <button class="input-group-text text-light-green"><i class="fab fa-telegram-plane"></i>
                        </button>
                                            </div>
                                        </div>
                                        <div class="input-group-append col-xl-4">
                                            <button class="btn-second btn-submit full-width" type="button">Find food</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                