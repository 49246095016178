<!-- Navigation -->
<div class="header">
    <header class="full-width">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mainNavCol">
                    <!-- logo -->
                    <div class="logo mainNavCol">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" class="img-fluid" alt="Logo">
                        </a>
                    </div>
                    <!-- logo -->
                    <div class="main-search mainNavCol">
                        <form class="main-search search-form full-width">
                            <div class="row">
                                <!-- location picker -->
                                <div class="col-lg-6 col-md-5">
                                    <a href="javascript:void(0)" class="delivery-add p-relative"> <span class="icon"><i class="fas fa-map-marker-alt"></i></span>
                                        <span class="address">Lafayette, LA</span>
                                    </a>
                                    <!-- <div class="location-picker">
                                        <input type="text" class="form-control" placeholder="Enter a new address">
                                    </div> -->
                                </div>
                                <!-- location picker -->
                                <!-- search -->
                                <!-- <div class="col-lg-6 col-md-7">
                                    <div class="search-box padding-10">
                                        <input type="text" class="form-control" placeholder="Pizza, Burger, Chinese">
                                    </div>
                                </div> -->
                                <!-- search -->
                            </div>
                        </form>
                    </div>
                    <div class="right-side fw-700 mainNavCol">
                        <!-- <div class="gem-points">
                            <a href="javascript:void(0)"> <i class="fas fa-concierge-bell"></i>
                                <span>Order Now</span>
                            </a>
                        </div> -->
                        <div class="catring parent-megamenu">
                            <a href="javascript:void(0)"> <span>More <i class="fas fa-caret-down"></i></span>
                                <i class="fas fa-bars"></i>
                            </a>
                            <div class="megamenu">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-5">
                                                <div class="ex-collection-box h-100">
                                                    <!-- <a href="#"> -->
                                                        <img src="assets/img/nav-1.jpg" class="img-fluid full-width h-100" alt="image">
                                                    <!-- </a> -->
                                                    <div class="category-type overlay padding-15"> 
                                                        <!-- <a routerLink="/restaurant" class="category-btn">Top rated</a> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-8 col-md-7">
                                                <div class="row">
                                                    <div class="col-lg-3 col-sm-6">
                                                        <div class="menu-style">
                                                            <div class="menu-title">
                                                                <h6 class="cat-name">Products</h6>
                                                            </div>
                                                            <ul>
                                                                <li><a routerLink="/" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products</a>
                                                                </li>
                                                                <!-- <li><a routerLink="/homepage1" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 1</a>
                                                                </li>
                                                                <li><a routerLink="/homepage2" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 2</a>
                                                                </li>
                                                                <li><a routerLink="/homepage3" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 3</a>
                                                                </li>
                                                                <li><a routerLink="/homepage4" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Page 4</a>
                                                                </li> -->
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-sm-6">
                                                        <div class="menu-style">
                                                            <div class="menu-title">
                                                                <h6 class="cat-name">Blogs</h6>
                                                            </div>
                                                            <ul>
                                                                <!-- <li><a routerLink="/blog" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid View</a>
                                                                </li>
                                                                <li><a routerLink="/blog-style-2" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid View 2</a>
                                                                </li>
                                                                <li><a routerLink="/blog-details" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a>
                                                                </li>
                                                                <li><a routerLink="/ex-deals" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Ex Deals</a>
                                                                </li> -->
                                                                <li><a routerLink="/about" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-lg-3 col-sm-6">
                                                        <div class="menu-style">
                                                            <div class="menu-title">
                                                                <h6 class="cat-name"><a href="#" class="text-light-black">Related Pages</a></h6>
                                                            </div>
                                                            <ul>
                                                                <li><a routerLink="/restaurant" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Restaurant</a>
                                                                <li><a routerLink="/restaurant-style-1" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Restaurant 1</a>
                                                                </li>
                                                                <li><a routerLink="/restaurant-style-2" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Restaurant 2</a>
                                                                </li>
                                                                <li><a routerLink="/add-restaurant" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Add Restaurant</a>
                                                                </li>
                                                                <li><a routerLink="/listview" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">List View</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-lg-3 col-sm-6">
                                                        <div class="menu-style">
                                                            <div class="menu-title">
                                                                <h6 class="cat-name">Contact</h6>
                                                            </div>
                                                            <ul>
                                                                <!-- <li><a routerLink="/login" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login & Signup</a>
                                                                </li> -->
                                                                <li><a routerLink="/contact" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a>
                                                                </li>
                                                                <!-- <li><a routerLink="/checkout" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a>
                                                                </li>
                                                                <li><a routerLink="/orderdetails" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Order Details</a>
                                                                </li>
                                                                <li><a routerLink="/geolocator" class="text-light-white fw-500" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Geo Locator</a>
                                                                </li> -->
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-sm-6">
                                                        <div class="menu-style">
                                                            <div class="menu-title">
                                                                <h6 class="cat-name">Locations</h6>
                                                            </div>
                                                            <ul>
                                                                <li class="fw-600"><span class="text-light-white"> </span> <a href="https://g.co/kgs/6zXBVfw" class="text-custom-black" target="_blank">T. Moise Farms</a>
                                                                </li>
                                                                <li class="fw-600"><span class="text-light-white"> </span> <a href="https://g.co/kgs/aFyuV3p" class="text-custom-black" target="_blank">Chicken On The Bayou & Boudin Shop</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mobile-search">
                    <div class="mobile-address">
                        <a href="javascript:void(0)" class="delivery-add"  data-target="#address-box"> <span class="address">Lafayette, LA</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</div>
<div class="main-sec"></div>
<!-- Navigation -->