<app-navbar></app-navbar>

<!-- exclusive deals -->
    <section class="section-padding-top exclusive-deals">
        <div class="container">
            <div class="row section-padding-bottom u-line">
                <div class="col-md-7 align-self-center">
                    <div class="title">
                        <div class="deals-heading">
                            <h2 class="text-light-black fw-700">Discover exclusive deals with Perks</h2>
                            <p class="text-light-black">Quickmunch deals, coupons, promos, and more</p>
                            <a routerLink="/ex-deals" class="btn-second btn-submit">View Deals</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="deals-image">
                        <img src="assets/img/deals/banner-1.jpg" class="img-fluid full-width" alt="#">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- exclusive deals -->
    <!-- Featured partners -->
    <section class="featured-partners section-padding-top">
        <div class="container">
            <div class="row section-padding-bottom u-line">
                <div class="col-12">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title">Featured partners</h3>
                        <p class="text-light-black">Get exclusive deals and free food</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="featured-partners-slider swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="featured-product">
                                    <div class="featured-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/deals/360x178/shop-1.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                        <div class="overlay-2 padding-10"> <span class="deal-tag">Only on Quickmunch</span>
                                        </div>
                                    </div>
                                    <div class="featured-product-details">
                                        <div class="pro-logo">
                                            <img src="assets/img/logo-7.jpg" class="img-fluid" alt="#">
                                        </div>
                                        <div class="pro-title">
                                            <h6 class="mb-1"><a routerLink="/restaurant" class="text-light-black fw-600">Great Burger</a></h6>
                                            <p class="text-light-white fs-12 mb-1">American, Fast Food</p> <span class="text-orange">Closed. Next delivery at 8:30am.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="featured-product">
                                    <div class="featured-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/deals/360x178/shop-2.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                        <div class="overlay-2 padding-10"> <span class="deal-tag">Only on Quickmunch</span>
                                        </div>
                                    </div>
                                    <div class="featured-product-details">
                                        <div class="pro-logo">
                                            <img src="assets/img/logo-6.jpg" class="img-fluid" alt="#">
                                        </div>
                                        <div class="pro-title">
                                            <h6 class="mb-1"><a routerLink="/restaurant" class="text-light-black fw-600">Flavor Town</a></h6>
                                            <p class="text-light-white fs-12 mb-1">Breakfast, Lunch & Dinner</p> <span class="text-orange">Closed. Next delivery at 8:30am.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="featured-product">
                                    <div class="featured-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/deals/360x178/shop-3.jpg" class="img-fluid full-width" alt="#">
                                        </a>
                                        <div class="overlay-2 padding-10"> <span class="deal-tag">Only on Quickmunch</span>
                                        </div>
                                    </div>
                                    <div class="featured-product-details">
                                        <div class="pro-logo">
                                            <img src="assets/img/logo-5.jpg" class="img-fluid" alt="#">
                                        </div>
                                        <div class="pro-title">
                                            <h6 class="mb-1"><a routerLink="/restaurant" class="text-light-black fw-600">Big Bites</a></h6>
                                            <p class="text-light-white fs-12 mb-1">Pizzas, Fast Food</p> <span class="text-orange">Closed. Next delivery at 8:30am.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="featured-product">
                                    <div class="featured-img">
                                        <a routerLink="/restaurant">
                                            <img src="https://via.placeholder.com/360x178" class="img-fluid full-width" alt="#">
                                        </a>
                                        <div class="overlay-2 padding-10"> <span class="deal-tag">Only on Quickmunch</span>
                                        </div>
                                    </div>
                                    <div class="featured-product-details">
                                        <div class="pro-logo">
                                            <img src="https://via.placeholder.com/64" class="img-fluid" alt="#">
                                        </div>
                                        <div class="pro-title">
                                            <h6 class="mb-1"><a routerLink="/restaurant" class="text-light-black fw-600">Smile N’ Delight</a></h6>
                                            <p class="text-light-white fs-12 mb-1">Desserts, Beverages</p> <span class="text-orange">Closed. Next delivery at 8:30am.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Add Arrows -->
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Featured partners -->
    <!-- trending -->
    <section class="trending section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title">Trending near you</h3>
                        <p class="text-light-black">Explore deals at the most popular spots</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="trending-slider swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-1.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Great Burger</a></h6>
                                        </div>
                                        <p class="text-light-white">American, Fast Food</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-12.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Flavor Town</a></h6>
                                        </div>
                                        <p class="text-light-white">Breakfast, Lunch & Dinner</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-3.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Big Bites</a></h6>
                                        </div>
                                        <p class="text-light-white">Pizzas, Fast Food</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-2.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Smile N’ Delight</a></h6>
                                        </div>
                                        <p class="text-light-white">Desserts, Beverages</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-5.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Lil Johnny’s</a></h6>
                                        </div>
                                        <p class="text-light-white">Continental & Mexican</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Add Arrows -->
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- trending -->
    <!-- fresh deals -->
    <section class="fresh-deals section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title">Fresh deals nearby</h3>
                        <p class="text-light-black">Find new Restaurants with deals near you</p>
                    </div>
                </div>
                <div class="col-12">
                    <div class="fresh-deals-slider swiper-container">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-10.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Choice Foods</a></h6>
                                        </div>
                                        <p class="text-light-white">Indian, Chinese, Tandoor</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-11.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Great Burger</a></h6>
                                        </div>
                                        <p class="text-light-white">American, Fast Food</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-21.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Flavor Town</a></h6>
                                        </div>
                                        <p class="text-light-white">Breakfast, Lunch & Dinner</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-13.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Big Bites</a></h6>
                                        </div>
                                        <p class="text-light-white">Pizzas, Fast Food</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="product-box">
                                    <div class="product-img">
                                        <a routerLink="/restaurant">
                                            <img src="assets/img/restaurants/255x150/shop-19.jpg" class="img-fluid full-width" alt="product-img">
                                        </a>
                                    </div>
                                    <div class="product-caption">
                                        <div class="title-box">
                                            <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Smile N’ Delight</a></h6>
                                        </div>
                                        <p class="text-light-white">Desserts, Beverages</p>
                                        <div class="product-details">
                                            <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                                <span class="text-light-white price">$10 min</span>
                                            </div>
                                            <div class="rating"> <span>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                          <i class="fas fa-star text-yellow"></i>
                        </span>
                                                <span class="text-light-white text-right">4225 ratings</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product-footer-2">
                                        <div class="discount"> <span class="text-success fs-12">$3 off</span>
                                        </div>
                                        <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Add Arrows -->
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- fresh deals -->
    <!-- local deals -->
    <section class="local-deals section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header-left">
                        <h3 class="text-light-black header-title">More local deals</h3>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-box-2">
                            <div class="product-img">
                                <a routerLink="/restaurant">
                                    <img src="assets/img/deals/88x112/mdeals-1.jpg" class="img-fluid" alt="product-img">
                                </a>
                            </div>
                            <div class="product-caption">
                                <div class="title-box">
                                    <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Lil Johnny’s</a></h6>
                                </div>
                                <p class="text-light-white">Continental & Mexican</p>
                                <div class="product-details">
                                    <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                        <span class="text-light-white price">$10 min</span>
                                    </div>
                                    <div class="rating"> <span>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                    </span>
                                        <span class="text-light-white text-right">4225 ratings</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-footer-2">
                            <div class="discount"> <span class="text-success fs-12">$3 off</span>
                            </div>
                            <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-box-2">
                            <div class="product-img">
                                <a routerLink="/restaurant">
                                    <img src="assets/img/deals/88x112/mdeals-2.jpg" class="img-fluid" alt="product-img">
                                </a>
                            </div>
                            <div class="product-caption">
                                <div class="title-box">
                                    <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Choice Foods</a></h6>
                                </div>
                                <p class="text-light-white">Indian, Chinese, Tandoor</p>
                                <div class="product-details">
                                    <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                        <span class="text-light-white price">$10 min</span>
                                    </div>
                                    <div class="rating"> <span>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                    </span>
                                        <span class="text-light-white text-right">4225 ratings</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-footer-2">
                            <div class="discount"> <span class="text-success fs-12">$3 off</span>
                            </div>
                            <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-box-2">
                            <div class="product-img">
                                <a routerLink="/restaurant">
                                    <img src="assets/img/deals/88x112/mdeals-3.jpg" class="img-fluid" alt="product-img">
                                </a>
                            </div>
                            <div class="product-caption">
                                <div class="title-box">
                                    <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Great Burger</a></h6>
                                </div>
                                <p class="text-light-white">American, Fast Food</p>
                                <div class="product-details">
                                    <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                        <span class="text-light-white price">$10 min</span>
                                    </div>
                                    <div class="rating"> <span>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                    </span>
                                        <span class="text-light-white text-right">4225 ratings</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-footer-2">
                            <div class="discount"> <span class="text-success fs-12">$3 off</span>
                            </div>
                            <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-box-2">
                            <div class="product-img">
                                <a routerLink="/restaurant">
                                    <img src="assets/img/deals/88x112/mdeals-4.jpg" class="img-fluid" alt="product-img">
                                </a>
                            </div>
                            <div class="product-caption">
                                <div class="title-box">
                                    <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Flavor Town</a></h6>
                                </div>
                                <p class="text-light-white">Breakfast, Lunch & Dinner</p>
                                <div class="product-details">
                                    <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                        <span class="text-light-white price">$10 min</span>
                                    </div>
                                    <div class="rating"> <span>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                    </span>
                                        <span class="text-light-white text-right">4225 ratings</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-footer-2">
                            <div class="discount"> <span class="text-success fs-12">$3 off</span>
                            </div>
                            <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-box-2">
                            <div class="product-img">
                                <a routerLink="/restaurant">
                                    <img src="assets/img/deals/88x112/mdeals-5.jpg" class="img-fluid" alt="product-img">
                                </a>
                            </div>
                            <div class="product-caption">
                                <div class="title-box">
                                    <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Big Bites</a></h6>
                                </div>
                                <p class="text-light-white">Pizzas, Fast Food</p>
                                <div class="product-details">
                                    <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                        <span class="text-light-white price">$10 min</span>
                                    </div>
                                    <div class="rating"> <span>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                    </span>
                                        <span class="text-light-white text-right">4225 ratings</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-footer-2">
                            <div class="discount"> <span class="text-success fs-12">$3 off</span>
                            </div>
                            <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="product-box mb-xl-20">
                        <div class="product-box-2">
                            <div class="product-img">
                                <a routerLink="/restaurant">
                                    <img src="assets/img/deals/88x112/mdeals-6.jpg" class="img-fluid" alt="product-img">
                                </a>
                            </div>
                            <div class="product-caption">
                                <div class="title-box">
                                    <h6 class="product-title"><a routerLink="/restaurant" class="text-light-black">Smile N’ Delight</a></h6>
                                </div>
                                <p class="text-light-white">Desserts, Beverages</p>
                                <div class="product-details">
                                    <div class="price-time"> <span class="text-light-black time">30-40 min</span>
                                        <span class="text-light-white price">$10 min</span>
                                    </div>
                                    <div class="rating"> <span>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                      <i class="fas fa-star text-yellow"></i>
                    </span>
                                        <span class="text-light-white text-right">4225 ratings</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="product-footer-2">
                            <div class="discount"> <span class="text-success fs-12">$3 off</span>
                            </div>
                            <div class="discount-coupon"> <span class="text-light-white fs-12">First order only</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-md-center">
                <div class="col-md-2"> <a routerLink="/restaurant" class="btn-first white-btn text-light-green fw-600">View More</a>
                </div>
            </div>
        </div>
    </section>
    <!-- local deals -->

    <app-footer2></app-footer2>